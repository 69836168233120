<template>
  <div class="d-flex flex-column flex-root">

            <topbar></topbar>

    <v-tour v-if='["admin", "superadmin"].includes($store.state.user.role)' name="myTour" :steps="steps" :options="{ debug: true }"></v-tour>

              <transition name="fade-in-up">
                <router-view ref="content"/>
              </transition>

      <div style="text-align: center; background-color: #05386B; color: white;position: fixed;  bottom: 0px; width: 100%;">  
        ScarrCharts &copy; {{year}}
      </div>

  </div>

</template>

<script>

import topbar from '@/view/layout/header/Topbar';
//import Loader from "@/view/content/Loader.vue";
//import { bus } from '@/main';
import * as fb from '@/firebase';
import moment from "moment";

export default {
  name: "Layout",
  components: { topbar },
  mounted: function () {
     if(["admin", "superadmin"].includes(this.$store.state.user.role) && this.showTour){
       console.log("starting tour.");
        this.$tours['myTour'].start();

     fb.usersCollection
       .doc(this.$store.state.user.email)
       .update({"showTour": false})
       .then(() => {
          console.log('showTour updated.')
      }); 
     }
  },
  data: function() {
      let that = this;

      return {
        steps: [
          {
            target: '#v-step-0',  // We're using document.querySelector() under the hood
            header: {
              title: 'Get Started',
            },
            content: `Take a brief tour.`,
            params: {
              enableScrolling: false
            }
          }, 
          {
            target: '#v-step-1',
            content: 'Click this to see information about the current tab.',
            params: {
              placement: 'right',
              enableScrolling: false
            },
             before:  function(){ 
                        return new Promise((resolve) => {
                          // Time-consuming UI/async operation here.
                          console.log("that.$refs.content.$refs.tabsBox.$refs.savedCharts=", that.$refs.content.$refs.tabsBox.$refs.savedCharts);

                          that.$refs.content.$refs.tabsBox.$refs.savedCharts.mini = false;
                          that.$refs.content.$refs.tabsBox.$refs.savedCharts.selectedSidebarComponent = "getStarted";
                          resolve('foo');
                        });
                      }
          },
          {
            target: '#v-step-2',
            content: 'Click here to add a tab.',
            params: {
                placement: 'top', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
                enableScrolling: false
              }
          },
          {
            target: '#v-step-3',
            content: 'Click here to <b>change</b> the active tab.',
            params: {
                placement: 'right', 
                enableScrolling: false
              }/*,
            before:  function(){ 
                        return new Promise((resolve) => {
                          // Time-consuming UI/async operation here.

                          bus.$emit('newTab', "BasicCharts");
                          resolve('foo');
                        });
                      }*/
          },
          {
            target: '#v-step-4',  // We're using document.querySelector() under the hood
            content: `Save tabs here.`,
            params: {
                      placement: 'right',
                      enableScrolling: false
            },
            before:  function(){ 
                        return new Promise((resolve) => {
                          // Time-consuming UI/async operation here.
                          console.log("that.$refs.content.$refs.tabsBox.$refs.savedCharts=", that.$refs.content.$refs.tabsBox.$refs.savedCharts);

                          that.$refs.content.$refs.tabsBox.$refs.savedCharts.mini = false;
                          that.$refs.content.$refs.tabsBox.$refs.savedCharts.selectedSidebarComponent = "chartSaving";
                          resolve('foo');
                        });
                      }
          }
        ]
      }
  },
  computed: {
    showTour(){
      return this.$store.state.user.showTour;
    },
    year(){
      return moment().format("YYYY");
    }
  }
};
</script>